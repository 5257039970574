import { useContext, useEffect, useState } from 'react';
import '../styles/ConfirmationPayment.css';
import DetailsList from './DetailsList';
import '../styles/Details.css';
import { Typography, Link } from '@mui/material';
import swishIconText from '../svgs/swishIconText.svg';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { UserSelectionData, getStateFromLocalStorage } from 'src/utils/local-storage-helper';
import { useNavigate } from 'react-router-dom';
import { Footer } from './Footer';
import handPhone from '../svgs/handPhone.svg';
import { SendReceiptDialog } from './SendReceiptDialog';
import { useLogAmplitudeEvent } from 'src/hooks/useLogAmplitudeEvent';
import { EAmplitudeEvent } from 'src/types/amplitude-event';
import { formatCurrencyDecimal } from 'src/utils/formatting';
import { AppContext } from 'src/context/createDataContext';
import { EActions } from 'src/context/PaymentContext';
import Feedback from './Feedback';

export default function ConfirmationPayment() {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const [receiptDialogOpen, setReceiptDialogOpen] = useState(false);
  const logAmplitudeEvent = useLogAmplitudeEvent();

  const type = window.navigator.userAgent;
  const downloadLink =
    type.includes('iPhone') || type.includes('Mac OS')
      ? 'https://apps.apple.com/us/app/id1580733496'
      : 'https://play.google.com/store/apps/details?id=com.aimoone.app&hl=en_US';

  useEffect(() => {
    const sessionState = getStateFromLocalStorage();
    dispatch({ type: EActions.UpdateWithLastSession, payload: { userSelectionData: sessionState } });
    if (!sessionState.transactionId && !sessionState.parkingSessionId) {
      if (sessionState.parkingZone.code && sessionState.parkingZone.uid) {
        navigate(`/parkingZone/${sessionState.parkingZone.code}/${sessionState.parkingZone.uid}`);
      } else {
        navigate('/');
      }
    }

    logAmplitudeEvent({
      event: EAmplitudeEvent.BOOKING_COMPLETED,
      ...(sessionState as UserSelectionData),
    });
  }, []);

  const getViewDetails = () => {
    return [
      { title: t('ConfirmationPayment.startTime'), value: dayjs(state.startParkingTime).format('YYYY-MM-DD HH:mm') },
      { title: t('ConfirmationPayment.endTime'), value: dayjs(state.endParkingTime).format('YYYY-MM-DD HH:mm') },
      {
        title: t('ConfirmationPayment.address'),
        value: `(${state.parkingZone.code}) ${state.parkingZone.address.street}`,
      },
      { title: t('ConfirmationPayment.licensePlate'), value: state.licensePlate },
      {
        title: t('ConfirmationPayment.amount'),
        value: `${formatCurrencyDecimal(state.parkingCost)} ${t('Common.currency')}`,
      },
    ];
  };

  const renderSentReceiptTextWhenSuccess = () => {
    return (
      <div className="centerContent styledBox">
        <div className="centerContent">
          {t('ConfirmationPayment.receiptSent')}: {state.userEmail}.
        </div>
        <div className="centerContent underlinedText resendText" onClick={() => setReceiptDialogOpen(true)}>
          {t('ConfirmationPayment.resend')}
        </div>
      </div>
    );
  };

  return (
    <div className="confirmationPaymentContainerGrouped">
      <div className="getAimoApp">
        <div className="getAimoAppText">
          <div className="smallerTitle centeredContent">
            {t('Header.getAppText1')} <br />
            {t('Header.getAppText2')} <br />
            <Link
              href={downloadLink}
              className="aimoLink"
              target="_blank"
              rel="noreferrer noopener"
              onClick={() => logAmplitudeEvent({ event: EAmplitudeEvent.DOWNLOAD_APP, email: state.userEmail ?? '' })}
            >
              {t('Header.downloadApp')}
            </Link>
          </div>
        </div>

        <div className="getAimoAppIcon">
          <img alt="download Aimo App" src={handPhone} className="handPhone" />
        </div>
      </div>

      <div className="confirmationPaymentContainer">
        <div>
          <Feedback />
        </div>
        <div className="confirmationTitle subTitle">{t('ConfirmationPayment.details')}</div>
        <div className="confirmationListDetails">
          <DetailsList detailsItems={getViewDetails()} />
          <div className="detailContainer">
            <Typography className="detailsTitle">{t('ConfirmationPayment.paidWith')}</Typography>
            <div className="detailsValue">
              <img alt="Swish" src={swishIconText} className="swishIcon" />
            </div>
          </div>
          {renderSentReceiptTextWhenSuccess()}
        </div>
        <SendReceiptDialog open={receiptDialogOpen} setOpen={setReceiptDialogOpen} />
        <Footer />
      </div>
    </div>
  );
}
