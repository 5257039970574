import * as amplitude from '@amplitude/analytics-browser';
import { useMemo, useCallback, useContext } from 'react';
import { TAmplitudeEventPayload } from '../types/amplitude-event';
import { config } from 'src/config/config';
import { AppContext } from 'src/context/createDataContext';

amplitude.init(config.REACT_APP_AMPLITUDE);

export const useLogAmplitudeEvent = () => {
  const { state } = useContext(AppContext);
  const parkingZoneCode = state?.parkingZone?.code;

  const instance = useMemo<amplitude.Types.BrowserClient | undefined>(
    () => (config.REACT_APP_AMPLITUDE && amplitude) || undefined,
    [],
  );

  const track: (options: TAmplitudeEventPayload) => void = useCallback(
    (options: TAmplitudeEventPayload) => {
      if (!options) return;
      const { event } = options;
      if (instance && event) {
        instance.track(event, { ...options, ...(parkingZoneCode && { parkingZoneCode }) });
      }
    },
    [instance, parkingZoneCode],
  );

  return track;
};
