import React, { useContext, useEffect, useState } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import ChipsList from './ChipsList';
import swishIcon from '../svgs/swish.svg';
import swishIconDisabled from '../svgs/swish_secondary.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useDynamicGetRequest } from 'src/hooks/useGetRequest';
import { PricingEstimate, PricingInfoResponse } from 'src/types/pricing-estimate';
import { ParkingAvailability, ParkingZoneResponse } from 'src/types/parking-zone';
import '../styles/StartingNowForm.css';
import { useTranslation } from 'react-i18next';
import { formatParkingTime } from 'src/utils/time-converter';
import { Footer } from './Footer';
import { useLogAmplitudeEvent } from 'src/hooks/useLogAmplitudeEvent';
import { EAmplitudeEvent } from 'src/types/amplitude-event';
import { clearSessionDataFromLocalStorage } from 'src/utils/local-storage-helper';
import { formatCurrencyDecimal } from 'src/utils/formatting';
import { AppContext } from 'src/context/createDataContext';
import { EActions } from 'src/context/PaymentContext';
import { isBoolean, validateEmail, validateLicensePlate } from 'src/utils/validator-helper';
import dayjs from 'dayjs';
import { getValidServiceFee } from 'src/utils/common-helpers';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);


export default function StartingNowForm() {
  const { state, dispatch } = useContext(AppContext);
  const { zoneCode, zoneUid } = useParams();
  const { get: getParkingZone } = useDynamicGetRequest<ParkingZoneResponse>();
  const { get: getPricingEstimate } = useDynamicGetRequest<PricingInfoResponse>();
  const [pricingEstimate, setPricingEstimates] = useState<PricingEstimate[]>([]);
  const [gotPricingEstimate, setGotPricingEstimate] = useState<boolean>(false);
  const [pricingEstimateError, setPricingEstimateError] = useState<boolean>(false);
  const [parkingAvailability, setParkingAvailability] = useState<ParkingAvailability>();
  const [serviceFee, setServiceFee] = useState<string>('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const logAmplitudeEvent = useLogAmplitudeEvent();

  useEffect(() => {
    clearSessionDataFromLocalStorage();
    const handlePricingEstimateError = () => {
      setPricingEstimates([]);
      setPricingEstimateError(true);
    };

    const asyncGet = async () => {
      const { error: parkingZoneError, data: parkingZoneData } = await getParkingZone(
        `/api/v1/parking-zone/${zoneCode}${zoneUid ? `?parkingZoneUid=${zoneUid}` : ''}`,
      );
      if (parkingZoneError) {
        navigate('/');
      } else if (parkingZoneData) {
        logAmplitudeEvent({
          event: EAmplitudeEvent.OPENED_PARKING_ZONE,
          parkingZoneCode: zoneCode || '',
        });
        const { uid: newZoneCodeUid, address: newZoneAddress, parkingAvailability } = parkingZoneData.data.zone;
        setParkingAvailability(parkingAvailability);

        if (newZoneCodeUid && newZoneAddress) {
          const { error: pricingEstimateError, data: pricingEstimateData } = await getPricingEstimate(
            `/api/v1/pricing/${newZoneCodeUid}/pricingInfo?startDateTime=${new Date().toISOString()}`,
          );
          if (pricingEstimateError) {
            handlePricingEstimateError();
          } else {
            setGotPricingEstimate(true);
            setPricingEstimateError(false);
            setPricingEstimates(pricingEstimateData?.data.suggestions ?? []);

            const newTariffPlan = pricingEstimateData?.data.tariffPlan;

            setServiceFee(getValidServiceFee(newTariffPlan?.addOns || []));

            dispatch({
              type: EActions.UpdateZone,
              payload: {
                parkingZone: {
                  code: parseInt(zoneCode ?? ''),
                  uid: newZoneCodeUid,
                  address: newZoneAddress,
                  tariffPlan: newTariffPlan,
                  parkingAvailability
                },
              },
            });
          }
        }
      }
    };
    asyncGet();
  }, [zoneCode]);

  const handleLicensePlateChange = (event: React.FormEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const regExp = /[A-Za-z0-9_]/;
    if (event.currentTarget.value === '' || regExp.test(event.currentTarget.value)) {
      dispatch({
        type: EActions.UpdateLicensePlate,
        payload: { licensePlate: event.currentTarget.value.toUpperCase() },
      });
    }
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    navigate(`/processPayment`);
  };

  const isValid = () => {
    return (
      validateEmail(state.userEmail || '') &&
      validateLicensePlate(state.licensePlate) &&
      state.userEmail &&
      state.licensePlate.length > 1 &&
      state.licensePlate.length < 9 &&
      (state.parkingCost !== 0 || state.endParkingTime.isValid()) &&
      !isParkingDisabled()
    );
  };

  const isParkingDisabled = () => {
    return isBoolean(parkingAvailability?.isPermitted) ? !parkingAvailability?.isPermitted : false;
  }

  return (
    <div className="parkingPaymentContainer">
      {
        !isParkingDisabled() ?
          <div className="startingNowTitle smallTitle">{t('StartingNowForm.startingNow')}</div> :
          <div className="forbiddenMessageTile">
            <Typography className="forbiddenMessage">
              {`${t("StartingNowForm.forbiddenParking")} ${parkingAvailability?.allowedFrom ? formatParkingTime(dayjs(parkingAvailability?.allowedFrom), dayjs(parkingAvailability?.allowedFrom).isToday() ? 'HH:mm' : 'HH:mm, MMM D') : ''}`}
            </Typography>
          </div>
      }

      <div className="formContainer">
        <ValidatorForm className=" startingNowForm" onSubmit={handleSubmit}>
          <div className="chipsContainer">
            <ChipsList isDisabled={isParkingDisabled()} chipsItems={pricingEstimate} hasSuggestions={gotPricingEstimate} />
          </div>
          {pricingEstimateError && (
            <div className="errorContainer">
              <Typography>{t('Errors.pricingSuggestions')}</Typography>
            </div>
          )}
          <div className="serviceFeeInfo">
            <Typography>
              <span className="grayText">
                {t('StartingNowForm.serviceFee').replace('**price**', `${serviceFee}${t('Common.currency')}`)}
              </span>
            </Typography>
          </div>
          <div className="parkingDetails">
            <Typography className='parkingUntil'>
              <span>{t('StartingNowForm.until')}</span> {!isParkingDisabled() ? formatParkingTime(state.endParkingTime, dayjs(state.endParkingTime).isToday() ? 'HH:mm' : 'HH:mm, MMM D') : ''}
            </Typography>
          </div>

          <TextValidator
            name="licensePlate"
            validators={['matchRegexp:^[A-Za-z0-9]+$', 'matchRegexp:^.{2,8}$']}
            errorMessages={[t('Errors.licensePlateFormat'), t('Errors.licensePlateLength')]}
            required
            label={t('StartingNowForm.licensePlate')}
            value={state.licensePlate}
            onChange={handleLicensePlateChange}
            className="formField licensePlate"
            InputLabelProps={{ className: 'mediumText' }}
            InputProps={{ className: 'mediumText' }}
            disabled={isParkingDisabled()}
          />

          <TextValidator
            name="emailAddress"
            validators={['isEmail']}
            errorMessages={[t('Errors.emailFormat')]}
            type="email"
            required
            label={t('StartingNowForm.email')}
            value={state.userEmail}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              dispatch({ type: EActions.UpdateUserEmail, payload: { userEmail: e.currentTarget.value } })
            }
            className="formField emailAddress"
            InputLabelProps={{ className: 'mediumText' }}
            InputProps={{ className: 'mediumText' }}
            disabled={isParkingDisabled()}
          />

          <div className="termsAndConditions">
            <Typography>
              {' '}
              {t('StartingNowForm.agreeTerms')}{' '}
              <a
                href="https://docs.aimopark.io/TnC-Scan-and-pay.html"
                className="grayLink"
                target="_blank"
                rel="noreferrer"
              >
                {t('StartingNowForm.terms')}
              </a>
            </Typography>
          </div>

          <div className="buttonContainer">
            <Button
              variant="contained"
              type="submit"
              className={`buttonStyle payWithSwish ${isValid() ? '' : 'buttonDisabled'}`}
            >
              {t('StartingNowForm.pay')} {formatCurrencyDecimal(state.parkingCost)} {t('Common.currency')}
              <img alt="Swish" src={isValid() ? swishIcon : swishIconDisabled} className="swishIcon" />
            </Button>
          </div>
        </ValidatorForm>
      </div>
      <Footer />
    </div>
  );
}
