import { useTranslation } from 'react-i18next';
import '../styles/FeedbackForm.css';
import Typography from '@mui/material/Typography/Typography';
import FeedbackRatingItem from './FeedbackRatingItem';
import sadSmiley from '../svgs/sadSmiley.png';
import happySmiley from '../svgs/happySmiley.svg';
import neutralSmiley from '../svgs/neutralSmiley.svg';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useContext, useState } from 'react';
import { AppContext } from 'src/context/createDataContext';
import Button from '@mui/material/Button/Button';
import { useDynamicPostRequest } from 'src/hooks/usePostRequest';
import { FeedbackRequest, FeedbackResponse } from 'src/types/feedback';
import Box from '@mui/material/Box/Box';
import Grow from '@mui/material/Grow/Grow';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useLogAmplitudeEvent } from 'src/hooks/useLogAmplitudeEvent';
import { EAmplitudeEvent } from 'src/types/amplitude-event';
import CheckBox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

type FeedbackFormProps = {
    setFormSubmit: (state: boolean) => void
}

export default function FeedbackForm({ setFormSubmit }: FeedbackFormProps) {
    const { state, dispatch } = useContext(AppContext);
    const { t } = useTranslation();
    const [feedbackDetails, setFeedbackDetails] = useState<string>('');
    const [feedbackRating, setFeedbackRating] = useState<string>('');
    const [contactAgreement, setContactAgreement] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const { post } = useDynamicPostRequest<FeedbackRequest, FeedbackResponse>();
    const logAmplitudeEvent = useLogAmplitudeEvent();

    const handleFeedbackDetailsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFeedbackDetails(event.currentTarget.value);
    }

    const ratings = [{
        id: 'BAD',
        altText: 'Bad',
        iconContext: sadSmiley,
        reviewLabel: `${t('Feedback.awfulReviewLabel')}`
    }, {
        id: 'NEUTRAL',
        altText: 'Neutral',
        iconContext: neutralSmiley,
        reviewLabel: `${t('Feedback.badReviewLabel')}`
    }, {
        id: 'GREAT',
        altText: 'Great',
        iconContext: happySmiley,
        reviewLabel: `${t('Feedback.greatReviewLabel')}`
    }];

    const handleRatingItemClick = (rating: string) => {
        setFeedbackRating(rating);
        logAmplitudeEvent({
            event: EAmplitudeEvent.SELECT_FEEDBACK_RATING,
            feedbackRating: rating,
            email: state.userEmail ?? '',
            parkingSessionId: state.parkingSessionId ?? ''
        });
    }

    const submitFeedback = async () => {
        const { error, data } = await post(
            {
                email: state.userEmail ?? '',
                rating: feedbackRating,
                details: feedbackDetails,
                contactAgreement
            },
            `/api/v1/feedback/${state.parkingSessionId}`,
        );

        if (error) {
            setErrorMessage(t('Feedback.submitError') || 'Something went wrong! Please try again later.');
            window.localStorage.setItem('feedbackSubmitted', 'false');
        } else {
            setFormSubmit(true)
            window.localStorage.setItem('feedbackSubmitted', 'true');
            window.localStorage.setItem('feedbackRating', feedbackRating);
        }

        logAmplitudeEvent({
            event: EAmplitudeEvent.FEEDBACK_SUBMITTED,
            feedbackRating,
            feedbackDetails: feedbackDetails,
            email: state.userEmail ?? '',
            parkingSessionId: state.parkingSessionId ?? ''
        });
    }

    const handleFeedbackSubmit = async () => {
        setErrorMessage('');
        await submitFeedback();
    }

    const handleContactAgreementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContactAgreement(event.target.checked);
    }

    return (
        <>
            <Typography className="detailsTitle feedbackSubtTitle">{t('Feedback.subTitle')}</Typography>
            <ValidatorForm className='feedbackForm' onSubmit={handleFeedbackSubmit}>
                <div className='feedbackSmileyParentContainer'>
                    {ratings.map(rating => {
                        const selected = feedbackRating === rating.id;
                        return <div key={rating.id} onClick={() => handleRatingItemClick(rating.id)}><FeedbackRatingItem iconContext={rating.iconContext} altText={rating.altText} selected={selected} /></div>
                    })}
                </div>
                {feedbackRating ?
                    (<div className='feedbackSecondaryForm'>
                        <Box>
                            <Grow in={feedbackRating !== ''}>
                                <div>
                                    <TextareaAutosize
                                        className='formField feedbackDetails'
                                        placeholder={(ratings.find(({ id }) => id === feedbackRating))?.reviewLabel || ''}
                                        maxLength={300}
                                        value={feedbackDetails}
                                        onChange={handleFeedbackDetailsChange}
                                    />
                                    <div className='contactAgreementContainer'>
                                     <FormControlLabel control={
                                        <CheckBox
                                        className='consent-check-box'
                                            checked={contactAgreement} 
                                            onChange={handleContactAgreementChange}
                                        />
                                    } label={`${t('Feedback.contactAgreementText')}`}/>
                                    </div>
                                    <div className="buttonContainer feedbackSubmit">
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            className={`feedbackSubmit buttonStyle ${feedbackRating !== '' ? '' : 'buttonDisabled'}`}
                                        >
                                            {t('Feedback.SubmitButtonLabel')}
                                        </Button>
                                    </div>
                                </div>
                            </Grow>
                        </Box>
                        {errorMessage && (
                            <div className="errorMessageContainer">
                                <p>{errorMessage}</p>
                            </div>
                        )}
                    </div>
                    ) : <></>}
            </ValidatorForm>
        </>
    );

}
