import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import greenLogo from '../svgs/APLogoGreen.svg';
import '../styles/Footer.css';

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footerContainer">
      <ul className="footerLinkContainer">
        <li>
          <Link href="/FAQ">
            <span className="footerText">{t('FAQ.title')}</span>
          </Link>
        </li>
        <li>
          <Link
            href="
            https://www.aimopark.se/om-aimo-park/om-webbplatsen/integritetspolicy/"
            target="_blank"
            rel="noreferrer"
          >
            <span className="footerText">{t('Footer.privacyPolicy')}</span>
          </Link>
        </li>
        <li>
          <Link
            href="
            https://docs.aimopark.io/TnC-Scan-and-pay.html"
            target="_blank"
            rel="noreferrer"
          >
            <span className="footerText">{t('Footer.termsAndConditions')}</span>
          </Link>
        </li>
      </ul>
      <div className="footerLogoContainer">
        <Link 
          href="https://www.aimopark.se/" 
          target="_blank" 
          rel="noreferrer"
        >
          <img src={greenLogo} alt="Aimo Park" />
        </Link>
      </div>
    </div>
  );
};
