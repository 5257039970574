import { Outlet } from 'react-router-dom';
import Header from 'src/components/Header';

type MainLayoutProps = {
  showDownloadAimoAppText: boolean;
};

export const MainLayout = (props: MainLayoutProps) => {
  return (
    <div className="mainContainer">
      <Header showDownloadAimoAppText={props.showDownloadAimoAppText} />
      <Outlet />
    </div>
  );
};
