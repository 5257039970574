import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Link } from '@mui/material';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDynamicPostRequest } from 'src/hooks/usePostRequest';
import { ParkingSessionRequest, ParkingSessionResponse } from 'src/types/parking-session';
import { getStateFromLocalStorage, storeStateInLocalStorage } from 'src/utils/local-storage-helper';
import Spinner, { SpinnerSize } from './Spinner';
import { useLogAmplitudeEvent } from 'src/hooks/useLogAmplitudeEvent';
import { EAmplitudeEvent } from 'src/types/amplitude-event';
import { config } from 'src/config/config';
import { EPaymentErrorTypes } from 'src/types/payment';
import { AppContext } from 'src/context/createDataContext';
import { EActions } from 'src/context/PaymentContext';

export const ProcessPayment = () => {
  const { post } = useDynamicPostRequest<ParkingSessionRequest, ParkingSessionResponse>();
  const { state, dispatch } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const logAmplitudeEvent = useLogAmplitudeEvent();
  const [walletUrl, setWalletUrl] = useState<string | undefined>();

  useEffect(() => {
    if (window.localStorage.getItem('transactionId')) {
      getStateFromLocalStorage();
      const timeOutId = setTimeout(() => {
        handleProcessPaymentError(EPaymentErrorTypes.TIME_OUT);
      }, config.REACT_APP_TRXN_TIMEOUT);
      const eventSource = new EventSource(
        `${config.REACT_APP_SERVER_URL}/api/v1/payment/status/${window.localStorage.getItem('transactionId')}`,
      );
      eventSource.addEventListener('object', function (e) {
        const event = JSON.parse(e.data);
        clearTimeout(timeOutId);
        if(event?.data?.transactionId === window.localStorage.getItem('transactionId')) {
          if (event.data.status === 'SUCCESS') {
            eventSource.close();
            navigate(`/confirmationPayment`);
          } else if(event.data.status === 'ERRORED') {
            eventSource.close();
            clearTimeout(timeOutId);
            handleProcessPaymentError(EPaymentErrorTypes.SSE_ERROR);
          }
        }
      });
      eventSource.onerror = () => {
        eventSource.close();
        clearTimeout(timeOutId);
        handleProcessPaymentError(EPaymentErrorTypes.SSE_ERROR);
      };
    }
  });

  const handleProcessPaymentError = (errorType: EPaymentErrorTypes) => {
    logAmplitudeEvent({
      event: EAmplitudeEvent.PROCESSING_PAYMENT_ERROR,
      errorType,
      email: state.userEmail,
    });
    navigate('/errorProcessPayment', { state: { errorType } });
  };

  useEffect(() => {
    async function startParkingSession() {
      logAmplitudeEvent({
        event: EAmplitudeEvent.PAY_NOW,
        ...state,
      });

      const result = await post(
        {
          licensePlate: state.licensePlate,
          email: state.userEmail ?? '',
          parkingZoneUid: state.parkingZone.uid,
          startDateTime: dayjs(state.startParkingTime).toISOString(),
          endDateTime: dayjs(state.endParkingTime).toISOString(),
          price: state.parkingCost,
        },
        `/api/v1/parking-session`,
      );
      if (result.error) {
        handleStartParkingSessionError(result.error);
      } else {
        dispatch({ type: EActions.UpdateParkingSessionId, payload: { parkingSessionId: result.data?.data._id ?? '' } });
        setWalletUrl(result.data?.data.walletUrl);
        window.location.assign(result.data?.data.walletUrl ?? '');
        storeStateInLocalStorage({
          parkingCost: state.parkingCost,
          parkingSessionId: result.data?.data._id ?? '',
          parkingZone: { code: state.parkingZone.code ?? 0, address: state.parkingZone.address, uid: state?.parkingZone?.uid },
          endParkingTime: state.endParkingTime,
          startParkingTime: state.startParkingTime,
          licensePlate: state.licensePlate,
          userEmail: state.userEmail ?? '',
          transactionId: result.data?.data.transactionId ?? '',
        });
        window.localStorage.setItem('transactionId', result.data?.data.transactionId ?? '');
      }
    }
    if (!window.localStorage.getItem('transactionId')) {
      startParkingSession();
    }
  }, []);

  const handleStartParkingSessionError = (e: AxiosError) => {
    if (e.response?.status === 409) {
      handleProcessPaymentError(EPaymentErrorTypes.CONFLICT);
    } else {
      handleProcessPaymentError(EPaymentErrorTypes.UNKNOWN);
    }
  };

  return (
    <>
      <div className="processingPaymentContainer">
        <Typography className="processingPaymentTitle">{t('ProcessPayment.title')}</Typography>
        <Typography className="processingPaymentText">
          {t('ProcessPayment.waitInstructions')}{' '}
          {walletUrl && (
            <Link href={walletUrl}>
              <span className="greenLink">{t('ProcessPayment.clickHere')}</span>
            </Link>
          )}
        </Typography>
        <Spinner size={SpinnerSize.LARGE} />
      </div>
    </>
  );
};
