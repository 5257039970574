import { useTranslation } from 'react-i18next';
import '../styles/Feedback.css';
import Typography from '@mui/material/Typography/Typography';
import heart from '../svgs/heart.svg';
import { useContext, useState } from 'react';
import FeedbackForm from './FeedbackForm';
import dayjs from 'dayjs';
import { AppContext } from 'src/context/createDataContext';

export default function Feedback() {
    const { t } = useTranslation();
    const { state } = useContext(AppContext);
    const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);

    const showFeedbackForm = () => {
        return !feedbackSubmitted && !(window.localStorage.getItem('feedbackSubmitted') === 'true');
    }

    return (
        <div className="feedbackContainer">
            <div className='confirmationTitle subTitle feedbackTitle'>
                {`${t('Feedback.title')} ${dayjs(state.endParkingTime).format('HH:mm')}`}
            </div>

            {showFeedbackForm() ?
                (<FeedbackForm setFormSubmit={setFeedbackSubmitted} />)
                :
                (<div className='feedbackSubmittedContainer'>
                    <img alt={'altText'} src={heart} />
                    <Typography className="detailsTitle feedbackMessage">{t('Feedback.submitMessage')}</Typography>
                </div>)
            }
        </div>
    );

}
