import dayjs, { Dayjs } from 'dayjs';
import { TariffPlan } from 'src/types/pricing-estimate';
import { UserSelectionData } from 'src/utils/local-storage-helper';
import { ParkingAvailability, ParkingZoneAddress } from 'src/types/parking-zone';

export type ParkingZone = {
  code?: number;
  uid: string;
  address: ParkingZoneAddress;
  tariffPlan?: TariffPlan;
  parkingAvailability: ParkingAvailability;
};

export type ApplicationState = {
  parkingZone: ParkingZone;
  userInputZone?: string;
  licensePlate: string;
  parkingCost: number;
  startParkingTime: Dayjs;
  endParkingTime: Dayjs;
  sendReceipt: boolean;
  parkingSessionId?: string;
  userEmail?: string;
};

export const INITIAL_STATE: ApplicationState = {
  parkingZone: {
    code: 6543,
    uid: '',
    address: {
      street: '',
      number: '',
      postCode: '',
      city: '',
      municipality: '',
      province: '',
      countryCode: '',
      marker: '',
    },
    parkingAvailability: {
      isPermitted: true,
      allowedFrom: '',
      allowedTill: ''
    }
  },
  licensePlate: '',
  userEmail: '',
  parkingCost: 0,
  startParkingTime: dayjs(null),
  endParkingTime: dayjs(null),
  sendReceipt: false
};

export type TAction = {
  type: string;
  payload: Partial<ApplicationState>;
};

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum EActions {
  UpdateZone = 'update_zone',
  UpdateLicensePlate = 'update_license_plate',
  UpdateParkingCost = 'update_parking_cost',
  UpdateStartParkingTime = 'update_start_parking_time',
  UpdateEndParkingTime = 'update_end_parking_time',
  UpdateUserInputZone = 'update_user_input_zone',
  UpdateParkingSessionId = 'update_parking_session_id',
  UpdateUserEmail = 'update_user_email',
  UpdateWithLastSession = 'update_with_last_session',
  UpdateSendReceipt = 'update_send_receipt',
  Clear = 'clea_all_data'
}

export type ApplicationStatePayload = {
  [EActions.UpdateZone]: {
    parkingZone: ParkingZone;
  };
  [EActions.UpdateLicensePlate]: {
    licensePlate: string;
  };
  [EActions.UpdateParkingCost]: {
    parkingCost: number;
  };
  [EActions.UpdateStartParkingTime]: {
    startParkingTime: Dayjs;
  };
  [EActions.UpdateEndParkingTime]: {
    endParkingTime: Dayjs;
  };
  [EActions.UpdateUserInputZone]: {
    userInputZone?: string;
  };
  [EActions.UpdateParkingSessionId]: {
    parkingSessionId: string;
  };
  [EActions.UpdateUserEmail]: {
    userEmail: string;
  };
  [EActions.UpdateWithLastSession]: {
    userSelectionData: UserSelectionData;
  };
  [EActions.UpdateSendReceipt]: {
    sendReceipt: boolean;
  };
  [EActions.Clear]: undefined;
};

export type ApplicationActions = ActionMap<ApplicationStatePayload>[keyof ActionMap<ApplicationStatePayload>];

export const paymentReducer = (state: ApplicationState, action: ApplicationActions): ApplicationState => {
  switch (action.type) {
    case EActions.UpdateZone:
      return {
        ...state,
        parkingZone: action.payload.parkingZone,
      };
    case EActions.UpdateLicensePlate:
      return {
        ...state,
        licensePlate: action.payload.licensePlate,
      };
    case EActions.UpdateParkingCost:
      return {
        ...state,
        parkingCost: action.payload.parkingCost,
      };
    case EActions.UpdateStartParkingTime:
      return {
        ...state,
        startParkingTime: action.payload.startParkingTime,
      };
    case EActions.UpdateEndParkingTime:
      return {
        ...state,
        endParkingTime: action.payload.endParkingTime,
      };
    case EActions.UpdateUserInputZone:
      return {
        ...state,
        userInputZone: action.payload.userInputZone,
      };
    case EActions.UpdateParkingSessionId:
      return {
        ...state,
        parkingSessionId: action.payload.parkingSessionId,
      };
    case EActions.UpdateUserEmail:
      return {
        ...state,
        userEmail: action.payload.userEmail,
      };
    case EActions.UpdateWithLastSession:
      return {
        ...state,
        parkingZone: {
          ...state.parkingZone,
          code: action.payload.userSelectionData.parkingZone.code,
          address: {
            ...state.parkingZone.address,
            street: action.payload.userSelectionData.parkingZone.address.street,
            number: action.payload.userSelectionData.parkingZone.address.number,
          },
        },
        licensePlate: action.payload.userSelectionData.licensePlate,
        userEmail: action.payload.userSelectionData.userEmail,
        parkingCost: action.payload.userSelectionData.parkingCost,
        startParkingTime: action.payload.userSelectionData.startParkingTime,
        endParkingTime: action.payload.userSelectionData.endParkingTime,
        parkingSessionId: action.payload.userSelectionData.parkingSessionId,
      };
    case EActions.UpdateSendReceipt:
      return {
        ...state,
        sendReceipt: action.payload.sendReceipt,
      };
    case EActions.Clear:
      return INITIAL_STATE;
    default:
      return state;
  }
};
