import { Dayjs } from 'dayjs';
import { Duration } from 'iso8601-duration';
import { Duration as DayjsDuration } from 'dayjs/plugin/duration';

export const convertParkingTime = (unconvertedTime: string): string => {
  const time = unconvertedTime.replace('PT', '');
  const timeUnit = time.charAt(time.length - 1);
  if (timeUnit === 'H') {
    return (parseInt(time.substring(0, time.length - 1)) / 24).toString();
  } else if (timeUnit === 'M') {
    return parseInt(time.substring(0, time.length - 1)).toString();
  } else {
    return '';
  }
};

export const ISOToDayJs = (ISODuration: Duration, startTime: Dayjs) => {
  return startTime
    .add(ISODuration.days ?? 0, 'days')
    .add(ISODuration.hours ?? 0, 'hours')
    .add(ISODuration.minutes ?? 0, 'minute');
};

export const formatParkingTime = (endParkingTime: Dayjs, format: string) => {
  if (endParkingTime.isValid()) {
    return endParkingTime.format(format);
  }

  return '00:00';
};

export const DayJsDurationToReadableTimeString = (dateTime: DayjsDuration): string => {
  return dateTime.format('D[d] H[h] m[m]')
    .replace(/\b0m\b/, '')
    .replace(/\b0d\b/, '')
    .replace(/\b0h\b/, '');
}