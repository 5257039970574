export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/
        );
};

export const validateLicensePlate = (licensePlate: string) => {
    return !(/[^A-Za-z0-9]/g).test(licensePlate);
}

export const isBoolean = (val: any) => 'boolean' === typeof val;