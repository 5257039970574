import { Outlet } from 'react-router-dom';
import PlainHeader from 'src/components/PlainHeader';
import '../styles/ProcessPayment.css';

export const PlainLayout = () => {
  return (
    <div className="mainContainer white">
      <PlainHeader />
      <Outlet />
    </div>
  );
};
