import '../styles/FeedbackRatingItem.css';

type RatingItemProps = {
    iconContext: string;
    altText: string;
    selected: boolean;
}

export default function FeedbackRatingItem({ iconContext, altText, selected }: RatingItemProps) {

    return (
        <div className={`${selected ? 'feedbackRatingItemSelected' : 'feedbackRatingItem'}`}>
            <img alt={altText} src={iconContext} />
        </div>
    );
}