import { Typography } from '@mui/material';

export type DetailsProps = {
  detailsTitle: string;
  detailsValue: string | number; 
};

export default function DetailsItem({ detailsTitle, detailsValue }: DetailsProps) {
 
  return (
    <div className="detailContainer">
      <Typography className="detailsTitle">{detailsTitle}</Typography>
      <Typography className="detailsValue">{detailsValue}</Typography>
    </div>
  );
}
