export enum HTTPRequestTypes {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
}

export type SignatureData = {
  httpMethod: HTTPRequestTypes;
  uri: string;
  requestBody?: object;
};
