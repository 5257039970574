import { useContext } from 'react';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import greenLogo from '../svgs/APLogoGreen.svg';
import whiteGlobe from '../svgs/APGlobeWhite.svg';
import '../styles/Header.css';
import { AppContext } from 'src/context/createDataContext';

export default function PlainHeader() {
  const { i18n } = useTranslation();
  const { state } = useContext(AppContext);

  const handleSwitchLanguage = () => {
    const currentLanguage = i18n.resolvedLanguage;
    const newLanguage = currentLanguage === 'en' ? 'sv' : 'en';
    i18n.changeLanguage(newLanguage);
  };
  return (
    <div className="headerContainer">
      <div className="topContainer">
        <div className="logoContainer">
          <Link href={state.parkingZone && state.parkingZone.code && state.parkingZone.uid ? `/parkingZone/${state.parkingZone.code}/${state.parkingZone.uid}` : '/'}>
            <img alt="Aimo Park" src={greenLogo} className="aimoParkLogo" />
          </Link>
        </div>
        <div className="languageSwitchContainer" onClick={handleSwitchLanguage}>
          <div className="iconContainer">
            <img alt="globe" className="globeImg" src={whiteGlobe} />
          </div>
          <div className="actionText">{i18n.resolvedLanguage === 'en' ? 'Svenska' : 'English'}</div>
        </div>
      </div>
    </div>
  );
}
