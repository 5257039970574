interface Config {
  REACT_APP_SERVER_URL: string;
  REACT_APP_SERVER_KEY: string;
  REACT_APP_SERVER_ID: string;
  REACT_APP_AMPLITUDE: string;
  REACT_APP_TRXN_TIMEOUT: number;
  REACT_APP_NOTIFICATION_ON: string; 
  REACT_APP_CLARITY_TOKEN: string;
}

const generateConfig = (): Config => {
  if (process.env.NODE_ENV === 'development') {
    return {
      REACT_APP_SERVER_URL: process.env.REACT_APP_SERVER_URL ?? '',
      REACT_APP_SERVER_KEY: process.env.REACT_APP_SERVER_KEY ?? '',
      REACT_APP_SERVER_ID: process.env.REACT_APP_SERVER_ID ?? '',
      REACT_APP_AMPLITUDE: process.env.REACT_APP_AMPLITUDE ?? '',
      REACT_APP_TRXN_TIMEOUT: parseInt(process.env.REACT_APP_TRXN_TIMEOUT ?? ''),
      REACT_APP_NOTIFICATION_ON: process.env.REACT_APP_NOTIFICATION_ON ?? 'false',
      REACT_APP_CLARITY_TOKEN: process.env.REACT_APP_CLARITY_TOKEN ?? ''
    };
  } else {
    return {
      REACT_APP_SERVER_URL: window.__RUNTIME_CONFIG__.REACT_APP_SERVER_URL,
      REACT_APP_SERVER_KEY: window.__RUNTIME_CONFIG__.REACT_APP_SERVER_KEY,
      REACT_APP_SERVER_ID: window.__RUNTIME_CONFIG__.REACT_APP_SERVER_ID,
      REACT_APP_AMPLITUDE: window.__RUNTIME_CONFIG__.REACT_APP_AMPLITUDE,
      REACT_APP_TRXN_TIMEOUT: window.__RUNTIME_CONFIG__.REACT_APP_TRXN_TIMEOUT,
      REACT_APP_NOTIFICATION_ON: window.__RUNTIME_CONFIG__.REACT_APP_NOTIFICATION_ON,
      REACT_APP_CLARITY_TOKEN: window.__RUNTIME_CONFIG__.REACT_APP_CLARITY_TOKEN
    };
  }
};

export const config = generateConfig();
