import dayjs, { Dayjs } from 'dayjs';

export interface UserSelectionData extends Record<string, any> {
  parkingZone: {
    code: number;
    uid: string;
    address: {
      street: string;
      number: string;
    };
  };
  licensePlate: string;
  userEmail: string;
  parkingCost: number;
  startParkingTime: Dayjs;
  endParkingTime: Dayjs;
  parkingSessionId: string;
  transactionId: string;
}

export const storeSpecificStateInLocalStorage = (state: Partial<UserSelectionData>) => {
  Object.keys(state).map(key => {
    window.localStorage.setItem(key, state[key]);
  });
}

export const storeStateInLocalStorage = (data: UserSelectionData) => {
  window.localStorage.setItem('parkingZoneCode', data.parkingZone.code.toString());
  window.localStorage.setItem('parkingZoneUid', data.parkingZone.uid);
  window.localStorage.setItem('parkingZoneStreet', data.parkingZone.address.street);
  window.localStorage.setItem('parkingZoneStreetNumber', data.parkingZone.address.number);
  window.localStorage.setItem('licensePlate', data.licensePlate);
  window.localStorage.setItem('userEmail', data.userEmail);
  window.localStorage.setItem('parkingCost', data.parkingCost.toString());
  window.localStorage.setItem('startParkingTime', data.startParkingTime.toISOString());
  window.localStorage.setItem('endParkingTime', data.endParkingTime.toISOString());
  window.localStorage.setItem('parkingSessionId', data.parkingSessionId);
  window.localStorage.setItem('transactionId', data.transactionId);
};

export const clearSessionDataFromLocalStorage = () => {
  window.localStorage.removeItem('parkingCost');
  window.localStorage.removeItem('startParkingTime');
  window.localStorage.removeItem('endParkingTime');
  window.localStorage.removeItem('parkingSessionId');
  window.localStorage.removeItem('transactionId');
  window.localStorage.removeItem('feedbackSubmitted');
  window.localStorage.removeItem('feedbackRating');
};

export const getStateFromLocalStorage = (): UserSelectionData => {
  return {
    parkingZone: {
      code: parseFloat(window.localStorage.getItem('parkingZoneCode') ?? '0'),
      uid: window.localStorage.getItem('parkingZoneUid') ?? '',
      address: {
        street: window.localStorage.getItem('parkingZoneStreet') ?? '',
        number: window.localStorage.getItem('parkingZoneStreetNumber') ?? '',
      },
    },
    licensePlate: window.localStorage.getItem('licensePlate') ?? '',
    userEmail: window.localStorage.getItem('userEmail') ?? '',
    parkingCost: parseFloat(window.localStorage.getItem('parkingCost') ?? '0'),
    startParkingTime: dayjs(window.localStorage.getItem('startParkingTime')) ?? dayjs(),
    endParkingTime: dayjs(window.localStorage.getItem('endParkingTime')) ?? dayjs(),
    parkingSessionId: window.localStorage.getItem('parkingSessionId') ?? '',
    transactionId: window.localStorage.getItem('transactionId') ?? '',
  };
};
