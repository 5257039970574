import { useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import { GenericResponseBody, PostResponse } from 'src/types/response';
import { HTTPRequestTypes } from 'src/authentication/authentication-types';
import { generateAuthHeaderValue } from 'src/authentication/authentication';
import { config } from 'src/config/config';

export const useDynamicPostRequest = <RequestBody extends object, ResponseBody>(): {
  post: (data: RequestBody, url: string) => Promise<PostResponse<ResponseBody>>;
} => {
  const baseUrl = config.REACT_APP_SERVER_URL;

  const post = useCallback(async (data: RequestBody, url: string) => {
    try {
      const response = await axios.post(`${baseUrl}${url}`, data, getHeaders(url, data));
      return { data: response.data as GenericResponseBody<ResponseBody> };
    } catch (error) {
      return { error: error as AxiosError };
    }
  }, []);

  return { post };
};

const getHeaders = (url: string, requestBody: object) => {
  return {
    headers: {
      Authorization: `${generateAuthHeaderValue({ httpMethod: HTTPRequestTypes.POST, uri: url, requestBody })}`,
    },
  };
};
