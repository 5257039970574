import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FacilityDetails from './FacilityDetails';
import { SendReceiptDialog } from './SendReceiptDialog';
import greenLogo from '../svgs/APLogoGreen.svg';
import whiteGlobe from '../svgs/APGlobeWhite.svg';
import changeArrow from '../svgs/changeArrowWhite.svg';
import '../styles/Header.css';
import { useLogAmplitudeEvent } from 'src/hooks/useLogAmplitudeEvent';
import { EAmplitudeEvent } from 'src/types/amplitude-event';
import { CustomModal } from './CustomModal';
import { AppContext } from 'src/context/createDataContext';
import { EActions } from 'src/context/PaymentContext';
import { NotificationBanner } from './NotificationBanner';
import { config } from 'src/config/config';

type HeaderProps = {
  showDownloadAimoAppText: boolean;
};

export default function Header({ showDownloadAimoAppText }: HeaderProps) {
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [receiptDialogOpen, setReceiptDialogOpen] = useState(false);
  const logAmplitudeEvent = useLogAmplitudeEvent();
  const handleOpenDetails = () => {
    logAmplitudeEvent({
      event: EAmplitudeEvent.OPENED_ZONE_DETAILS,
      parkingZoneCode: state.parkingZone.code ? state.parkingZone.code.toString() : '',
    });
    setOpenDetails(true);
  };
  const handleCloseDetails = () => setOpenDetails(false);

  const changeZoneHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    dispatch({ type: EActions.UpdateUserInputZone, payload: {} });
    navigate('/');
  };

  const handleSwitchLanguage = () => {
    const currentLanguage = i18n.resolvedLanguage;
    const newLanguage = currentLanguage === 'en' ? 'sv' : 'en';
    logAmplitudeEvent({
      event: EAmplitudeEvent.SWITCHED_LANGUAGE,
      newLanguage,
    });
    i18n.changeLanguage(newLanguage);
  };
  
  return (
    <>
      {config.REACT_APP_NOTIFICATION_ON === 'true' && !showDownloadAimoAppText && (
        <NotificationBanner
            notificationInfo={t('Notification.info')}
            notificationRecommendation={t('Notification.recommendation')}
        />
      )}
      <div className="headerContainer">
        <div className="topContainer">
          <div className="logoContainer">
            <Link href={state.parkingZone && state.parkingZone.code && state.parkingZone.uid ? `/parkingZone/${state.parkingZone.code}/${state.parkingZone.uid}` : '/'}>
              <img alt="Aimo Park" src={greenLogo} className="aimoParkLogo" />
            </Link>
          </div>

          <div className="topActionContainer">
            {state.parkingZone.code && !showDownloadAimoAppText && (
              <div className="changeZone" onClick={changeZoneHandler}>
                <div className="iconContainer">
                  <img alt="change zone" src={changeArrow} />
                </div>
                <div className="actionText">{t('Header.changeZone')}</div>
              </div>
            )}
          </div>

          <div className="languageSwitchContainer" onClick={handleSwitchLanguage}>
            <div className="iconContainer">
              <img alt="globe" className="globeImg" src={whiteGlobe} />
            </div>
            <div className="actionText">{i18n.resolvedLanguage === 'en' ? 'Svenska' : 'English'}</div>
          </div>
        </div>

        <div className="topMainContainer">
          {!showDownloadAimoAppText && (
            <>
              <div className="smallTitle">{t('Header.start')}</div>
              <div
                className={`zoneNumberTitle ${
                  state.userInputZone || state.parkingZone.code ? 'darkBlueText' : 'grayText'
                } `}
              >
                {state.userInputZone || state.parkingZone.code ? state.userInputZone || state.parkingZone.code : '0000'}
              </div>

              {state.parkingZone.address && (
                <div className="zoneDetails">
                  <div className="zoneAddress">
                    <Typography>
                      <span className="darkBlueText">
                        {state.parkingZone.address
                          ? `${state.parkingZone.address.street} ${state.parkingZone.address.number || ''}`
                          : ''}
                      </span>
                    </Typography>
                  </div>
                  <div className="zoneDetailsLink" onClick={handleOpenDetails}>
                    <Typography>
                      <span className="grayText">{t('StartingNowForm.zoneDetails')}</span>
                    </Typography>
                  </div>
                </div>
              )}
            </>
          )}
          {showDownloadAimoAppText && <div className="thankYouTitle">{t('Header.thankYou')}</div>}
        </div>
        <SendReceiptDialog open={receiptDialogOpen} setOpen={setReceiptDialogOpen} />
        <CustomModal
          open={openDetails}
          onClose={handleCloseDetails}
          title={t('FacilityDetails.title')}
          ariaDescribedBy={'Facility Details'}
        >
          {state.parkingZone.tariffPlan && <FacilityDetails priceDetails={state.parkingZone.tariffPlan} />}
        </CustomModal>
      </div>
    </>
  );
}
