import { Button, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ReceiptResponse } from 'src/types/receipt';
import 'src/styles/SendReceiptDialog.css';
import ConfirmedAnimation from './ConfirmedAnimation';
import { useDynamicGetRequest } from 'src/hooks/useGetRequest';
import FailedAnimation from './FailedAnimation';
import { useLogAmplitudeEvent } from 'src/hooks/useLogAmplitudeEvent';
import { EAmplitudeEvent } from 'src/types/amplitude-event';
import { CustomModal } from './CustomModal';
import { AppContext } from 'src/context/createDataContext';
import { EActions } from 'src/context/PaymentContext';

type SendReceiptDialogProps = {
  open: boolean;
  setOpen: (condition: boolean) => void;
};

export const SendReceiptDialog = ({ open, setOpen }: SendReceiptDialogProps) => {
  const { state, dispatch } = useContext(AppContext);
  const { get } = useDynamicGetRequest<ReceiptResponse>();
  const [receiptSent, setReceiptSent] = useState(false);
  const [sendReceiptError, setSendReceiptError] = useState(false);
  const { t } = useTranslation();
  const logAmplitudeEvent = useLogAmplitudeEvent();

  const handleSendReceipt = async () => {
    logAmplitudeEvent({
      event: EAmplitudeEvent.SEND_RECEIPT,
      ...state,
    });
    setSendReceiptError(false);
    const response = await get(`/api/v1/parking-session/${state.parkingSessionId}/receipt?email=${state.userEmail}`);
    if (response?.data) {
      setReceiptSent(true);
      setTimeout(() => handleClose(), 3500);
    } else if (response?.error) {
      setSendReceiptError(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setReceiptSent(false);
    setSendReceiptError(false);
  };

  const renderSendReceiptContent = () => {
    return (
      <CustomModal
        open={open}
        onClose={handleClose}
        title={t('SendReceiptDialog.title')}
        ariaDescribedBy={'Receipt Dialog'}
      >
        <div className="sendReceiptContainer">
          <Typography>{t('SendReceiptDialog.instructions')}</Typography>
          <ValidatorForm className="sendReceiptForm" onSubmit={handleSendReceipt}>
            <TextValidator
              name="email"
              validators={['isEmail']}
              errorMessages={[t('Errors.emailFormat')]}
              type="email"
              required
              label={t('SendReceiptDialog.placeholder')}
              value={state.userEmail}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                dispatch({ type: EActions.UpdateUserEmail, payload: { userEmail: e.currentTarget.value } })
              }
              className="formField userEmail"
              InputLabelProps={{ className: 'mediumText' }}
              InputProps={{ className: 'mediumText' }}
            />
            <Button className="buttonStyle buttonSmall" type="submit">
              {t('SendReceiptDialog.send')}
            </Button>
          </ValidatorForm>
        </div>
      </CustomModal>
    );
  };

  const renderReceiptSentContent = () => {
    return (
      <CustomModal
        open={open}
        onClose={handleClose}
        title={t('SendReceiptDialog.receiptSent')}
        ariaDescribedBy={'Receipt Sent Dialog'}
      >
        <>
          <ConfirmedAnimation />
          <Typography className="receiptSentDialogText">{t('SendReceiptDialog.receiptSentText')}</Typography>
        </>
      </CustomModal>
    );
  };

  const renderReceiptErrorContent = () => {
    return (
      <CustomModal
        open={open}
        onClose={handleClose}
        title={t('Errors.receiptErrorTitle')}
        ariaDescribedBy={'Receipt Error Dialog'}
      >
        <>
          <FailedAnimation />
          <Typography className="receiptSentDialogText">{t('Errors.receiptErrorText')}</Typography>

          <Button className="buttonStyle buttonSmall" onClick={() => setSendReceiptError(false)}>
            {t('SendReceiptDialog.ok')}
          </Button>
        </>
      </CustomModal>
    );
  };

  return (
    <>
      {' '}
      {sendReceiptError
        ? renderReceiptErrorContent()
        : !receiptSent
        ? renderSendReceiptContent()
        : renderReceiptSentContent()}
    </>
  );
};
