import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { StyledEngineProvider } from '@mui/material/styles';
import './i18n';
import { AppProvider } from 'src/context/createDataContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <AppProvider>
    <StyledEngineProvider injectFirst>
      <App />
    </StyledEngineProvider>
  </AppProvider>,
);
