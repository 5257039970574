import { Box, Modal } from '@mui/material';
import closeIcon from '../svgs/close.svg';
import '../styles/CustomModal.css';

type CustomModalProps = {
  onClose: (event?: object, reason?: string) => void;
  open: boolean;
  title: string;
  children?: JSX.Element;
  ariaDescribedBy: string;
};

export const CustomModal = ({ onClose, open, title, ariaDescribedBy, children }: CustomModalProps) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <Modal aria-describedby={ariaDescribedBy} open={open} onClose={handleClose}>
      <Box className="customModal">
        <div className="modalContent">
          <div className="modalTitleAndCloseIcon">
            <div className="modalTitle subTitle">{title}</div>
            <div className="closeIcon" onClick={onClose}>
              <img alt="X" src={closeIcon} className="closeIcon" />
            </div>
          </div>
          <div className="modalChildren">{children}</div>
        </div>
      </Box>
    </Modal>
  );
};
