import '../styles/FacilityDetails.css';
import DetailsList from './DetailsList';
import '../styles/Details.css';
import { useTranslation } from 'react-i18next';
import { TariffPlan } from 'src/types/pricing-estimate';
import { parse } from 'iso8601-duration';
import { getValidServiceFee } from 'src/utils/common-helpers';

type FacilityDetailsProps = {
  priceDetails: TariffPlan;
};

export default function FacilityDetails({ priceDetails }: FacilityDetailsProps) {
  const { t } = useTranslation();

  const viewDetails1 = [
    { title: 'Entry', value: '06-22' },
    { title: 'Exit', value: '24/7' },
  ];

  const getPricingDetails = () => {
    const tariffStepDuration = parse(priceDetails?.tariffs[0].steps[0].duration);
    const tariffStepAmount = priceDetails?.tariffs[0].steps[0].amount;
    const maxParkingTime = priceDetails?.maximumParkingTime ? parse(priceDetails?.maximumParkingTime) : '';
    const serviceFee = getValidServiceFee(priceDetails?.addOns);

    return [
      {
        title: t('FacilityDetails.startPrice') + tariffStepDuration.minutes + t('FacilityDetails.min'),
        value: tariffStepAmount + t('Common.currency'),
      },
      {
        title: t('FacilityDetails.maxTime'),
        value: (maxParkingTime && maxParkingTime?.hours)
          ? (maxParkingTime?.hours > 24 ? maxParkingTime?.hours / 24 + ' ' + t('FacilityDetails.days') : maxParkingTime?.hours + ' ' + t('FacilityDetails.hours'))
          : 7 + ' ' + t('FacilityDetails.days'),
      },
      {
        title: t('FacilityDetails.serviceFee'),
        value: serviceFee + t('Common.currency'),
      },
    ];
  };

  return (
    <div className="facilityDetailsContainer">
      <div className="detailsSectionTitle smallTitle">{t('FacilityDetails.openHours')}</div>
      <div className="detailsListContainer">
        <DetailsList detailsItems={viewDetails1} />
      </div>
      <div className="detailsSectionTitle smallTitle">{t('FacilityDetails.pricing')}</div>
      <div className="detailsListContainer">
        <DetailsList detailsItems={getPricingDetails()} />
      </div>
    </div>
  );
}
