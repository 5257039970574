import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import '../styles/FAQPage.css';
import { Footer } from './Footer';

export const FAQPage = () => {
  const { t } = useTranslation();
  return (
    <div className="FAQContainer">
      <Typography className="FAQTitle boldText">{t('FAQ.title')}</Typography>
      <div className="questionsContainer">
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="boldText">{t('FAQ.q1')}</Typography>
          </AccordionSummary>
          <AccordionDetails >
            <Typography>{t('FAQ.a1')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="boldText">{t('FAQ.q2')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('FAQ.a2')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="boldText">{t('FAQ.q3')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('FAQ.a3')}</Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <Footer />
    </div>
  );
};
