import { useCallback, useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { GenericResponseBody, GetResponse } from 'src/types/response';
import { generateAuthHeaderValue } from 'src/authentication/authentication';
import { HTTPRequestTypes } from 'src/authentication/authentication-types';
import { config } from 'src/config/config';

export const useDynamicGetRequest = <T>(): {
  get: (url: string) => Promise<GetResponse<T>>;
} => {
  const baseUrl = config.REACT_APP_SERVER_URL;
  const get = useCallback(async (url: string) => {
    try {
      const response = await axios.get(`${baseUrl}${url}`, getHeaders(url));
      return { data: response.data as GenericResponseBody<T> };
    } catch (e) {
      return { error: e as AxiosError };
    }
  }, []);

  return { get };
};

export const useGetRequest = <T>(
  url: string,
): {
  data?: T | T[];
  error?: AxiosError;
  loading: boolean;
} => {
  const [data, setData] = useState<T | T[] | undefined>();
  const [error, setError] = useState<AxiosError | undefined>();
  const baseUrl = config.REACT_APP_SERVER_URL;
  useEffect(() => {
    const getAsync = async (url: string) => {
      try {
        const response = await axios.get(`${baseUrl}${url}`, getHeaders(url));
        return setData(response.data);
      } catch (e) {
        return setError(e as AxiosError);
      }
    };
    getAsync(url);
  });

  return { data, error, loading: !data && !error };
};

const getHeaders = (url: string) => {
  return {
    headers: {
      Authorization: `${generateAuthHeaderValue({ httpMethod: HTTPRequestTypes.GET, uri: url })}`,
    },
  };
};
