import { useContext } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ISOToDayJs } from 'src/utils/time-converter';
import { Duration } from 'iso8601-duration';
import dayjs from 'dayjs';
import { formatCurrencyDecimal } from 'src/utils/formatting';
import { AppContext } from 'src/context/createDataContext';
import { EActions } from 'src/context/PaymentContext';
import '../styles/ChipDetail.css';

export type ChipDetailsProps = {
  duration: string;
  isoDuration: Duration;
  tariffText: number;
  setChosenChip: (index: number) => void;
  index: number;
  selectedClass: (index: number) => void;
  disabled: boolean;
};

export default function ChipDetails({
  duration,
  isoDuration,
  tariffText,
  setChosenChip,
  index,
  selectedClass,
  disabled
}: ChipDetailsProps) {
  const { dispatch } = useContext(AppContext);
  const { t } = useTranslation();

  const handleTimeSelection = () => {
    dispatch({ type: EActions.UpdateParkingCost, payload: { parkingCost: tariffText } });
    setChosenChip(index);
    const now = dayjs();
    dispatch({ type: EActions.UpdateStartParkingTime, payload: { startParkingTime: now } });
    dispatch({ type: EActions.UpdateEndParkingTime, payload: { endParkingTime: ISOToDayJs(isoDuration, now) } });
  };

  const formatChipDuration = (textDuration: string) => {
    let chipDuration = textDuration;
    if (textDuration.includes('h')) {
      chipDuration = textDuration.replace('min', "'");
    }
    return chipDuration;
  };

  return (
    <div className={`chipContainer ${selectedClass(index)} ${disabled ? 'disabled': ''}`} onClick={handleTimeSelection}>
      <Typography className="timeStyle">{formatChipDuration(duration)}</Typography>
      <Typography className="tariffStyle">
        {formatCurrencyDecimal(tariffText)} {t('Common.currency')}
      </Typography>
    </div>
  );
}
