import React, { useContext, useEffect, useState } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { useDynamicGetRequest } from 'src/hooks/useGetRequest';
import { useNavigate } from 'react-router-dom';
import '../styles/ParkingZoneForm.css';
import { ParkingZoneResponse } from 'src/types/parking-zone';
import { PricingInfoResponse } from 'src/types/pricing-estimate';
import { useTranslation } from 'react-i18next';
import { Footer } from './Footer';
import { useLogAmplitudeEvent } from 'src/hooks/useLogAmplitudeEvent';
import { EAmplitudeEvent } from 'src/types/amplitude-event';
import { AppContext } from 'src/context/createDataContext';
import { EActions, INITIAL_STATE } from 'src/context/PaymentContext';

export default function ParkingZoneForm() {
  const { state, dispatch } = useContext(AppContext);
  const { get } = useDynamicGetRequest<ParkingZoneResponse>();
  const { get: getPricingEstimate } = useDynamicGetRequest<PricingInfoResponse>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const logAmplitudeEvent = useLogAmplitudeEvent();

  useEffect(() => {
    dispatch({
      type: EActions.UpdateZone,
      payload: { parkingZone: { uid: '', address: INITIAL_STATE.parkingZone.address, parkingAvailability: INITIAL_STATE.parkingZone.parkingAvailability } },
    });
  }, []);

  const handleParkingZoneCodeChange = (event: React.FormEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setErrorMessage('');
    const re = /^[0-9\b]+$/;

    if (event.currentTarget.value === '' || re.test(event.currentTarget.value)) {
      dispatch({ type: EActions.UpdateUserInputZone, payload: { userInputZone: event.currentTarget.value } });
    }
  };

  const handleError = (error: string | null, responseStatus: number | undefined) => {
    switch (responseStatus) {
      case 404:
        setErrorMessage('Invalid zone code. Please enter a valid zone code.');
        return;
      case 500:
        setErrorMessage('An unknown error occurred. Please contact aimox@aimopark.se if the error persists.');
        return;
      default:
        setErrorMessage(error || 'Unknown error occurred');
        return;
    }
  };

  const handleSubmit = async () => {
    logAmplitudeEvent({
      event: EAmplitudeEvent.SELECT_PARKING_ZONE,
      parkingZoneCode: state.userInputZone ?? '',
    });
    const { data, error } = await get(`/api/v1/parking-zone/${state.userInputZone}`);
    if (error) {
      handleError((error?.response?.data as any)?.message || error.message, error.response?.status);
    } else {
      const newZoneCodeUid = data?.data.zone.uid;
      const newZoneAddress = data?.data.zone.address;

      if (newZoneCodeUid && newZoneAddress) {
          dispatch({
            type: EActions.UpdateZone,
            payload: {
              parkingZone: {
                code: parseInt(state.userInputZone ?? ''),
                uid: newZoneCodeUid,
                address: newZoneAddress,
                parkingAvailability: data?.data.zone.parkingAvailability
              },
            },
          });
          navigate(`/parkingZone/${state.userInputZone}/${newZoneCodeUid}`);
      }
    }
  };

  return (
    <div className="parkingZoneContainer">
      <div className="parkingZoneTitle smallTitle">{t('ParkingZoneForm.title')}</div>

      <div className="formContainer">
        <ValidatorForm className="parkingZoneForm" onSubmit={handleSubmit}>
          <TextValidator
            name="parkingZone"
            validators={['matchRegexp:^[0-9]{4}$']}
            errorMessages={[t('Errors.zoneCodeFormat')]}
            required
            label={t('ParkingZoneForm.zoneCode')}
            value={state.userInputZone ? state.userInputZone : ''}
            onChange={handleParkingZoneCodeChange}
            className="formField"
            InputLabelProps={{ className: 'mediumText' }}
            InputProps={{ className: 'mediumText' }}
          />
          {errorMessage && (
            <div className="errorMessageContainer">
              <p>{errorMessage}</p>
            </div>
          )}
          <div className="buttonContainer">
            <Button
              variant="contained"
              type="submit"
              className={`buttonStyle ${state.userInputZone?.length !== 4 ? 'buttonDisabled' : ''}`}
              disabled={state.userInputZone?.length !== 4}
            >
              {state.userInputZone?.length !== 4
                ? t('ParkingZoneForm.select')
                : `${t('ParkingZoneForm.park')}`}
            </Button>
          </div>
        </ValidatorForm>
      </div>
      <Footer />
    </div>
  );
}
