import { Button, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { EPaymentErrorTypes } from 'src/types/payment';
import { AppContext } from 'src/context/createDataContext';

export const PaymentError = () => {
  const { t } = useTranslation();
  const { state } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(`/parkingZone/${state.parkingZone.code}/${state.parkingZone.uid}`);
  };

  const getPaymentErrorText = () => {
    switch (location.state.errorType) {
      case EPaymentErrorTypes.SSE_ERROR:
        return t('Errors.paymentSseError');
      case EPaymentErrorTypes.TIME_OUT:
        return t('Errors.paymentTimeOutError');
      case EPaymentErrorTypes.CONFLICT:
        return t('Errors.paymentConflictError');
      case EPaymentErrorTypes.UNKNOWN:
        return t('Errors.unknown');
      default:
        return t('Errors.unknown');
    }
  };

  return (
    <div className="processingPaymentContainer">
      <Typography className="processingPaymentErrorTitle">{t('Errors.paymentErrorTitle')}</Typography>
      <Typography className="processingPaymentErrorInfo">{getPaymentErrorText()}</Typography>
      <Button onClick={handleGoBack} className="buttonStyle buttonCentered">
        {t('ProcessPayment.goBack')}
      </Button>
    </div>
  );
};
