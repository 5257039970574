import { useState, useContext, useEffect } from 'react';
import { Typography, Button, Grid } from '@mui/material';
import ChipDetails from './ChipDetail';
import setTimeIcon from '../svgs/setTimeIcon.svg';
import setTimeIconWhite from '../svgs/setTimeIconWhite.svg';
import setTimeDisabledIcon from '../svgs/setTimeDisabledIcon.svg';
import { PricingEstimate } from 'src/types/pricing-estimate';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import '../styles/ChipsList.css';
import { Duration } from 'iso8601-duration';
import { useDynamicGetRequest } from 'src/hooks/useGetRequest';
import { PriceResponse } from 'src/types/price';
import { DayJsDurationToReadableTimeString, ISOToDayJs } from 'src/utils/time-converter';
import Spinner, { SpinnerSize } from './Spinner';
import { StaticTimePicker } from '@mui/x-date-pickers';
import { CustomModal } from './CustomModal';
import { AppContext } from 'src/context/createDataContext';
import { EActions } from 'src/context/PaymentContext';
import { getValidServiceFee } from 'src/utils/common-helpers';
import { ParkingConfig } from 'src/types/parking-session';
import { EAmplitudeEvent } from 'src/types/amplitude-event';
import { useLogAmplitudeEvent } from 'src/hooks/useLogAmplitudeEvent';

dayjs.extend(duration)

type ChipsFormProps = {
  chipsItems: Array<PricingEstimate>;
  isDisabled: boolean;
  hasSuggestions: boolean;
};

export type ChosenChipDetails = {
  duration: Duration;
  price: number;
  index: number;
};

export default function ChipsList({ chipsItems, isDisabled, hasSuggestions }: ChipsFormProps) {
  const { t } = useTranslation();
  const currentDateTime = dayjs();
  const [openTimeClock, setOpenTimeClock] = useState<boolean>(false);
  const handleCloseTimeClock = () => { setOpenTimeClock(false); setGotUpdatedConfig(false); };
  const [timeValue, setTimeValue] = useState<Dayjs | null>(currentDateTime);
  const [price, setPrice] = useState<number>(0);
  const { state, dispatch } = useContext(AppContext);
  const [chosenIndex, setChosenIndex] = useState<number>(0);
  const [gotUpdatedConfig, setGotUpdatedConfig] = useState<boolean>(false)
  const { get } = useDynamicGetRequest<PriceResponse & ParkingConfig>();
  const logAmplitudeEvent = useLogAmplitudeEvent();


  const getPrice = async (endTime: Dayjs | null) => {
    return get(
      `/api/v1/parking-session/price/${state.parkingZone.uid
      }?startDateTime=${dayjs().toISOString()}&endDateTime=${endTime?.toISOString()}`,
    );
  };

  const getParkingConfig = async () => {
    return get(
      `/api/v1/parking-session/${state.parkingZone.uid}/config`
    )
  }

  useEffect(() => {
    const preselectedDuration = chipsItems[-1];
    setChosenIndex(0);
    if (preselectedDuration) {
      const now = dayjs();

      dispatch({ type: EActions.UpdateStartParkingTime, payload: { startParkingTime: now } });
      dispatch({
        type: EActions.UpdateEndParkingTime,
        payload: { endParkingTime: ISOToDayJs(preselectedDuration.isoDuration, now) },
      });
      dispatch({
        type: EActions.UpdateParkingCost,
        payload: {
          parkingCost: preselectedDuration.estimatedPrice,
        },
      });
      setChosenIndex(chipsItems.indexOf(preselectedDuration) + 1);
    }
  }, [chipsItems]);

  const handleTimeChange = async (newValue: Dayjs | null) => {
    const { data } = await getPrice(newValue);
    setPrice(data?.data?.price || 0);
    setTimeValue(newValue);
  };

  const applyCustomTimeSelection = async () => {
    logAmplitudeEvent({
      event: EAmplitudeEvent.SUBMITTED_CUSTOM_TIME,
      parkingZoneCode: String(state.parkingZone.code || ''),
      timeValue: timeValue?.toISOString()
    });
    if (timeValue) {
      // TODO handle error
      const { data, error } = await getPrice(timeValue);
      if (data) {
        dispatch({ type: EActions.UpdateStartParkingTime, payload: { startParkingTime: dayjs() } });
        dispatch({
          type: EActions.UpdateEndParkingTime,
          payload: { endParkingTime: timeValue },
        });
        dispatch({
          type: EActions.UpdateParkingCost,
          payload: {
            parkingCost: data.data.price,
          },
        });
      }
    }
    handleCloseTimeClock();
  };

  const selectedClass = (index: number) => {
    return !isDisabled && index === chosenIndex && ((index !== 0 && state.endParkingTime.isValid()) || index === 0) ? 'selected' : '';
  };

  const handleOpenTimeClock = async () => {
    setChosenIndex(0);
    setOpenTimeClock(true);
    logAmplitudeEvent({
      event: EAmplitudeEvent.OPENED_CUSTOM_DIAL,
      parkingZoneCode: String(state.parkingZone.code || ''),
    });
    const { data, error } = await getParkingConfig();
    if (data) {
      dispatch({
        type: EActions.UpdateZone,
        payload: {
          parkingZone: {
            ...state.parkingZone,
            parkingAvailability: { ...data.data.config }
          },
        },
      });
      setGotUpdatedConfig(true);
    }
  };

  const actionBarComponent = () => {
    const buttonDisabled = timeValue?.isBefore(currentDateTime);
    return (
      <div>
        <Typography className='parkingRestriction'>{`${t('ChipsList.parkingRestriction')} ${DayJsDurationToReadableTimeString(dayjs.duration(dayjs(state.parkingZone.parkingAvailability.allowedTill).diff(dayjs((new Date()).setUTCSeconds(0)).subtract(1, 'second'))))}`}</Typography>
        {
          buttonDisabled ?
            <Button className="buttonStyle applyTimeChangeButton buttonDisabled" onClick={applyCustomTimeSelection}>
              {`${t('ChipsList.setEndTime')}`}
            </Button>
            :
            <Button className="buttonStyle applyTimeChangeButton" onClick={applyCustomTimeSelection}>
              {`${t('ChipsList.parkFor')} ${DayJsDurationToReadableTimeString(dayjs.duration((timeValue || dayjs()).diff(dayjs((new Date()).setUTCSeconds(0)))))}`}
            </Button>
        }
      </div>
    );
  };

  const toolbarComponent = () => {
    const serviceFee = getValidServiceFee(state.parkingZone.tariffPlan?.addOns || []);
    return <div className="timePickerToolbar">
      <Typography className='timeSelected'>{timeValue?.format('HH:mm')}</Typography>
      <Typography className='priceForTime'>{price}{t('Common.currency')}</Typography>
      {
        price !== 0 ?
          <Typography className='grayText'>({(t('ChipsList.serviceFee')).replace('**price**', serviceFee)})</Typography> :
          <></>
      }
    </div>;
  };

  return (
    <>
      {' '}
      <div className={`chipContainer setChip ${selectedClass(0)} ${isDisabled ? 'disabled' : ''}`} onClick={handleOpenTimeClock}>
        <img alt="Set" src={isDisabled ? setTimeDisabledIcon : (selectedClass(0) === '' ? setTimeIcon : setTimeIconWhite)} />
        <Typography className="tariffSyle">{t('ChipsList.set')}</Typography>
      </div>
      {hasSuggestions ? (
        chipsItems.map((chipItem, index) => {
          if (isDisabled || chipItem.isValid) {
            return (
              <ChipDetails
                setChosenChip={setChosenIndex}
                duration={chipItem.duration}
                isoDuration={chipItem.isoDuration}
                tariffText={chipItem.estimatedPrice}
                selectedClass={selectedClass}
                key={index}
                index={index + 1}
                disabled={isDisabled}
              />
            )
          }
        })
      ) : (
        <div className="spinnerContainer">
          <Spinner size={SpinnerSize.SMALL} />
        </div>
      )}
      <CustomModal
        open={openTimeClock}
        onClose={handleCloseTimeClock}
        ariaDescribedBy={'TimeClock Modal'}
        title={t('ChipsList.setEndTime')}
      >
        {
          gotUpdatedConfig ?
            <StaticTimePicker
              slots={{ actionBar: actionBarComponent, toolbar: toolbarComponent }}
              disablePast={true}
              value={timeValue}
              onChange={handleTimeChange}
              ampm={false}
              maxTime={dayjs(state.parkingZone.parkingAvailability.allowedTill)}
              className='staticTimePicker'
              disableIgnoringDatePartForTimeValidation={true}
            /> :
            <div className="spinnerContainer">
              <Spinner size={SpinnerSize.SMALL} />
            </div>
        }
      </CustomModal>
    </>
  );
}
