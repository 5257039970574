import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: window.localStorage.getItem('i18nextLng') || 'sv',
    fallbackLng: 'sv',
    supportedLngs: ['sv', 'en'],
  });

export default i18next;
