import { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ParkingZoneForm from './components/ParkingZoneForm';
import StartingNowForm from './components/StartingNowForm';
import ConfirmationPayment from './components/ConfirmationPayment';
import './styles/index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MainLayout } from './layouts/MainLayout';
import { ProcessPayment } from './components/ProcessPayment';
import { PlainLayout } from './layouts/ProcessPaymentLayout';
import { FAQPage } from './components/FAQPage';
import { PaymentError } from './components/PaymentError';
import { ZoneRedirection } from './components/ZoneRedirection';
import { clarity } from 'react-microsoft-clarity';
import { config } from './config/config';

function App() {
  clarity.init(config.REACT_APP_CLARITY_TOKEN || 'kwyujywo2z');

  const [showDownloadAimoAppText, setShowDownloadAimoAppText] = useState(false);

  useEffect(() => {
    setShowDownloadAimoAppText(false);
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout showDownloadAimoAppText={showDownloadAimoAppText} />}>
            <Route index element={<ParkingZoneForm />} />
          </Route>
          <Route
            path={`/parkingZone/:zoneCode/:zoneUid`}
            element={<MainLayout showDownloadAimoAppText={showDownloadAimoAppText} />}
          >
            <Route index element={<StartingNowForm />} />
          </Route>
          <Route
            path={`/parkingZone/:zoneCode`}
            element={<MainLayout showDownloadAimoAppText={showDownloadAimoAppText} />}
          >
            <Route index element={<StartingNowForm />} />
          </Route>
          <Route path={`/confirmationPayment`} element={<MainLayout showDownloadAimoAppText={true} />}>
            <Route index element={<ConfirmationPayment />} />
          </Route>
          <Route path={`/processPayment`} element={<PlainLayout />}>
            <Route index element={<ProcessPayment />} />
          </Route>
          <Route path={`/errorProcessPayment`} element={<PlainLayout />}>
            <Route index element={<PaymentError />} />
          </Route>

          <Route path={`/FAQ`} element={<PlainLayout />}>
            <Route index element={<FAQPage />} />
          </Route>
          <Route path={`/parkingZone/:zoneCode/trackingid=85941`}>
            <Route index element={<ZoneRedirection />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </LocalizationProvider>
  );
}

export default App;
