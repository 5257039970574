import { Player } from '@lottiefiles/react-lottie-player';
import '../styles/Spinner.css';

export enum SpinnerSize {
  SMALL = 'small',
  LARGE = 'large',
}

type SpinnerProps = {
  size: SpinnerSize;
};

const Spinner = (props: SpinnerProps) => (
  <div className={`wrapper ${props.size}`}>
    <Player autoplay loop src={require('./animation.json')} />
  </div>
);
export default Spinner;
