import React, { useReducer, ReactNode, createContext, Dispatch } from 'react';
import { ApplicationActions, ApplicationState, INITIAL_STATE, paymentReducer } from './PaymentContext';

type ReducerType = (state: ApplicationState, action: ApplicationActions) => ApplicationState;

type ProviderProps = {
  children: ReactNode;
};

const AppContext = createContext<{
  state: ApplicationState;
  dispatch: Dispatch<ApplicationActions>;
}>({
  state: INITIAL_STATE,
  dispatch: () => null,
});

const AppProvider: React.FC<ProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer<ReducerType>(paymentReducer, INITIAL_STATE);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
