import { SignatureData } from './authentication-types';
import CryptoJS from 'crypto-js';
import { v4 } from 'uuid';
import { Buffer } from 'buffer';
import { config } from 'src/config/config';

const getNonce = () => {
  return Buffer.from(v4()).toString('base64');
};

const encodeBody = (body: object) => {
  const hash = CryptoJS.MD5(body.toString());
  return hash.toString(CryptoJS.enc.Base64);
};

const getSignature = (signatureData: SignatureData, nonce: string, timeStamp: number) => {
  const base64Body = signatureData.requestBody ? encodeBody(signatureData.requestBody) : '';
  return CryptoJS.HmacSHA256(
    config.REACT_APP_SERVER_ID +
      signatureData.httpMethod +
      signatureData.uri +
      timeStamp.toString() +
      nonce +
      base64Body,
    config.REACT_APP_SERVER_KEY ?? '',
  ).toString(CryptoJS.enc.Base64);
};

export const generateAuthHeaderValue = (signatureData: SignatureData) => {
  const nonce = getNonce();
  const timeStamp = new Date().valueOf();
  return `${config.REACT_APP_SERVER_ID}:${getSignature(signatureData, nonce, timeStamp)}:${nonce}:${timeStamp}`;
};
