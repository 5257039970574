import DetailsItem from './DetailsItem';
import { DetailsContent } from 'src/types/details';

type DetailsProps = {
  detailsItems: Array<DetailsContent>;
};

export default function DetailsList({ detailsItems }: DetailsProps) {
  return (
    <>
      {detailsItems.map((detailItem, index) => (
        <DetailsItem detailsTitle={detailItem.title} detailsValue={detailItem.value} key={index} />
      ))}
    </>
  );
}
