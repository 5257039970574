import { Typography } from '@mui/material';
import '../styles/Notification.css';

export type NotificationProps = {
  notificationInfo: string;
  notificationRecommendation: string; 
};

export const NotificationBanner = ({ notificationInfo, notificationRecommendation }: NotificationProps) => {
  return (
    <div className="notificationContainer">
      <Typography className="notificationText">{notificationInfo}</Typography>
      <Typography className="notificationText">{notificationRecommendation}</Typography>
    </div>
  );
};
