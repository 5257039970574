import { ApplicationState } from 'src/context/PaymentContext';
import { UserSelectionData } from 'src/utils/local-storage-helper';
import { EPaymentErrorTypes } from './payment';

export enum EAmplitudeEvent {
  NAVIGATION = 'NAVIGATION',
  PAY_NOW = 'PAY_NOW',
  SEND_RECEIPT = 'SEND_RECEIPT',
  DOWNLOAD_APP = 'DOWNLOAD_APP',
  SELECT_PARKING_ZONE = 'SELECT_PARKING_ZONE',
  OPENED_PARKING_ZONE = 'OPENED_PARKING_ZONE',
  BOOKING_COMPLETED = 'BOOKING_COMPLETED',
  OPENED_ZONE_DETAILS = 'OPENED_ZONE_DETAILS',
  SWITCHED_LANGUAGE = 'SWITCHED_LANGUAGE',
  PROCESSING_PAYMENT_ERROR = 'PROCESSING_PAYMENT_ERROR',
  SELECT_FEEDBACK_RATING = 'SELECT_FEEDBACK_RATING',
  FEEDBACK_SUBMITTED = 'FEEDBACK_SUBMITTED',
  OPENED_CUSTOM_DIAL = 'OPENED_CUSTOM_DIAL',
  SUBMITTED_CUSTOM_TIME = 'SUBMITTED_CUSTOM_TIME'
}

export type TAmplitudeEventPayload = {
  event: EAmplitudeEvent;
} & (
  | TAmplitudeNavigationEventPayload
  | TAmplitudeSwishPaymentEventPayload
  | TAmplitudeSendReceiptEventPayload
  | TAmplitudeDownloadAppEventPayload
  | TAmplitudeSelectParkingZone
  | TAmplitudeOpenedParkingZone
  | TAmplitudeBookingCompleted
  | TAmplitudeZoneDetailsOpened
  | TAmplitudeSwitchedLanguage
  | TAmplitudeProcessPaymentError
  | TAmplitudeSelectFeedbackRating
  | TAmplitudeFeedbackSubmitted
  | TAmplitudeOpenedCustomTimeDial
  | TAmplitudeSubmittedCustomTime
);

export type TAmplitudeNavigationEventPayload = {
  event: EAmplitudeEvent.NAVIGATION;
  originUrl: string;
  targetUrl: string;
};

export type TAmplitudeSwishPaymentEventPayload = {
  event: EAmplitudeEvent.PAY_NOW;
} & ApplicationState;

export type TAmplitudeSendReceiptEventPayload = {
  event: EAmplitudeEvent.SEND_RECEIPT;
} & ApplicationState;

export type TAmplitudeDownloadAppEventPayload = {
  event: EAmplitudeEvent.DOWNLOAD_APP;
  email?: string;
};

export type TAmplitudeSelectParkingZone = {
  event: EAmplitudeEvent.SELECT_PARKING_ZONE;
  parkingZoneCode: string;
};

export type TAmplitudeOpenedParkingZone = {
  event: EAmplitudeEvent.OPENED_PARKING_ZONE;
  parkingZoneCode: string;
};

export type TAmplitudeBookingCompleted = {
  event: EAmplitudeEvent.BOOKING_COMPLETED;
} & UserSelectionData;

export type TAmplitudeZoneDetailsOpened = {
  event: EAmplitudeEvent.OPENED_ZONE_DETAILS;
  parkingZoneCode: string;
};

export type TAmplitudeSwitchedLanguage = {
  event: EAmplitudeEvent.SWITCHED_LANGUAGE;
  newLanguage: string;
};

export type TAmplitudeProcessPaymentError = {
  event: EAmplitudeEvent.PROCESSING_PAYMENT_ERROR;
  errorType: EPaymentErrorTypes;
  email?: string;
};

export type TAmplitudeSelectFeedbackRating = {
  event: EAmplitudeEvent.SELECT_FEEDBACK_RATING;
  feedbackRating: string;
  email: string;
  parkingSessionId: string;
};

export type TAmplitudeFeedbackSubmitted = {
  event: EAmplitudeEvent.FEEDBACK_SUBMITTED;
  feedbackRating: string;
  feedbackDetails?: string;
  email: string;
  parkingSessionId: string;
};

export type TAmplitudeOpenedCustomTimeDial = {
  event: EAmplitudeEvent.OPENED_CUSTOM_DIAL,
  parkingZoneCode: string
}

export type TAmplitudeSubmittedCustomTime = {
  event: EAmplitudeEvent.SUBMITTED_CUSTOM_TIME,
  parkingZoneCode: string,
  timeValue?: string
}